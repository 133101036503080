"use client";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { getAuthHeadersAndBody } from "@/helpers/web3auth/verifyToken";
import { useWeb3Authentication } from "@/context/web3AuthContext";

export type Inputs = {
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  image_url: string;
};

const RegistrationForm = () => {
  const { userData, authenticateUser, web3Auth, registerUser } = useWeb3Authentication();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    registerUser(data);
  };



  return (
    <div className="max-w-[600px] border-4 border-green-900 rounded-xl p-8">
      <form className="gap-2 flex flex-col justify-center" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="image_url" className="flex justify-center">
          {imagePreviewUrl ? (
            <img
              alt=""
              src={imagePreviewUrl}
              className="w-[150px] aspect-square bg-gray-500 rounded-xl object-cover"
            />
          ) : (
            <div className="w-[150px] aspect-square bg-gray-500 rounded-xl text-white font-mono flex justify-center items-center">
              Profile Image
            </div>
          )}
        </label>
        <input
          {...register("image_url")}
          onChange={(e) =>
            e.target.files ? setImagePreviewUrl(URL.createObjectURL(e.target.files[0])) : null
          }
          type="file"
          id="image_url"
          style={{ height: 0, padding: 0, margin: 0, border: "none" }}
        />
        <label htmlFor="first_name" className="font-mono">
          First Name
        </label>
        <input {...register("first_name", { required: true })} />
        {errors.first_name && <span>This field is required</span>}
        <label htmlFor="last_name" className="font-mono">
          Last Name
        </label>
        <input {...register("last_name")} />
        <label htmlFor="email_address" className="font-mono">
          Email Address
        </label>
        <input {...register("email_address", { required: true })} />
        {errors.email_address && <span>This field is required</span>}
        <label htmlFor="phone_number" className="font-mono">
          Phone Number
        </label>
        <input {...register("phone_number")} />
        <input
          className="cursor-pointer bg-green-900 hover:bg-transparent text-white font-mono"
          type="submit"
        />
      </form>
    </div>
  );
};

export default RegistrationForm;