"use client";
import { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useWeb3Authentication } from '@/context/web3AuthContext';
import RegistrationModal from '@/components/registrationModal/RegistrationModal';
import AnimatedLoaderButton from "@/components/animatedLoader/animatedLoaderButton";

export default function SignInPage() {
  const router = useRouter();
  const { login, web3Auth, userData } = useWeb3Authentication();
  const [isLoginStarted, setIsLoginStarted] = useState(false);


  const handleLogin = async () => {
    if (web3Auth?.connected && userData) {
      console.log("User is already connected, redirecting to dashboard");
      if (userData.access_level === "none") {
        setTimeout(() => {
          router.replace('/anon');
        }, 100);
      } else {
        setTimeout(() => {
          router.replace('/dashboard');
        }, 100);
      }
      return;
    }
    try {
      setIsLoginStarted(true);
      const newUserData = await login();

      if (newUserData && newUserData.access_level !== "none") {
        console.log("User is registered, redirecting to dashboard");
        setTimeout(() => {
          router.replace('/dashboard');
        }, 100);
      } else {
        console.log("User is not registered, showing registration modal");
        router.replace('/anon');
      }
    } catch (error) {
      console.error('Login failed:', error);
    } finally {
      setIsLoginStarted(false);
    }
  };

  return (
    <main className="flex bg-gradient-to-l from-black to-green-900 min-h-screen flex-col items-center justify-center p-24">
      <div className="w-64 h-64">
        {userData ? (
          !userData?.registered ? (
            <RegistrationModal 
              isOpen={true} 
              onClose={() => {
                setTimeout(() => {
                  router.replace('/dashboard');
                }, 100);
              }} 
            />
          ) : (
            <AnimatedLoaderButton 
              isLoading={isLoginStarted} 
              onClick={handleLogin}
              height={275}
            />
          )
        ) : (
          <AnimatedLoaderButton 
            isLoading={isLoginStarted} 
            onClick={handleLogin}
            height={275}
          />
        )}
      </div>
    </main>
  );
}

