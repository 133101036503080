import { useState, useEffect } from 'react';
import Styles from "./animatedLoader.module.css";
import Logo from "@/assets/tt-tree.png";

interface AnimatedLoaderProps {
  isLoading: boolean;
  onClick?: () => void;
  height: number;
}

const AnimatedLoaderButton: React.FC<AnimatedLoaderProps> = ({ isLoading, onClick, height }) => {
  const [className, setClassName] = useState(Styles.static);

  useEffect(() => {
    setClassName(isLoading ? `${Styles.animationLoader} h-${height}` : `${Styles.static} h-${height}`);
  }, [isLoading]);

  return (
    <img 
      className={className} 
      src={Logo.src} 
      alt="Logo" 
      onClick={!isLoading ? onClick : undefined}
      style={{ cursor: isLoading ? 'default' : 'pointer' }}
    />
  );
};

export default AnimatedLoaderButton;