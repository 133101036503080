// components/RegistrationModal.tsx
import React from 'react';
import RegistrationForm, { Inputs  } from '@/components/forms/userRegForm';

type RegistrationModalProps = {
  isOpen: boolean;
  onClose: () => void;

};

const RegistrationModal: React.FC<RegistrationModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-8">
        <h2 className="text-2xl font-bold mb-4">Registration</h2>
        <RegistrationForm />
        <button
          className="mt-4 px-4 py-2 bg-gray-200 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RegistrationModal;